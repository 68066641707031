export enum FeedbackEnum {
    POSITIVE = 'positive',
    NEGATIVE = 'negative'
};

export enum DocumentTypeEnum {
    FILE = 'file',
    SENSO = 'senso',
    WEB = 'web'
}

export enum KeyboardKeys {
    ENTER = 13,
    SPACE = 32,
    COMMA = 188
}