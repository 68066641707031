import { API_URL } from '../../config';
import { getAmplifySessionHeaders } from '../auth-service';
import axios from "axios";

const USER_API = `${API_URL}/users`;

export const getUserDetails = async () => {
    const headers = await getAmplifySessionHeaders();

    try {
        const response = await fetch(`${USER_API}/`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error getting user details:', error);
        throw error;
    }
}

export const uploadUserAvatar = async (file: File, email: string) => {

    const formData = new FormData();
    formData.append('image', file);
    formData.append('email', email);

    try {
        const response = await axios.post(`${USER_API}/avatar/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response;
    } catch (error) {
        console.error('Error uploading user avatar:', error);
        throw error;
    }
}

export const updateUser = async (reqBody: Record<'given_name' | 'family_name' | 'role_name', string | undefined>) => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.patch(`${USER_API}/update/`, reqBody, { headers });
        return response;
    } catch (error) {
        console.error('Error updating user details', error);
    }
}

export const onboardGenericInviteUser = async (inviteId: string, email: string, givenName: string, familyName: string, roleName: string) => {

    const reqBody = {
        invite_id: inviteId,
        email: email,
        given_name: givenName,
        family_name: familyName,
        role_name: roleName
    }

    try {
        const headers = await getAmplifySessionHeaders();
        await axios.post(`${USER_API}/invite/generic/onboard/`, reqBody, { headers });
    } catch (error) {
        console.error('Error onboarding user from generic invite', error);
    }
}

export const updateUserAuthDetails = async (cognitoId: string, email: string) => {
    const headers = { 'Content-Type': 'application/json' };

    const data = {
        cognito_id: cognitoId,
        email
    };

    try {
        const response = await fetch(`${USER_API}/auth/update/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error updating user's auth details:", error);
        throw error;
    }
}

export const linkExistingCognitoUser = async (cognitoId: string, email: string, inviteId: string) => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${USER_API}/link/`, { email, cognito_id: cognitoId, invite_id: inviteId }, { headers });
        return response;
    } catch (error) {
        console.error('Error linking existing user:', error);
    }
}

export const inviteUser = async (email: string, role?: number) => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${USER_API}/invite/create/invite_and_orguser/`, { email, role }, { headers });
        return response;
    } catch (error) {
        console.error('Error inviting user:', error);
    }
}

export const createGenericInviteLink = async () => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${USER_API}/invite/create/generic/`, {}, { headers });
        return response;
    } catch (error) {
        console.error('Error creating generic invite link:', error);
    }
}
