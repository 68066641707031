import React, { useEffect, useState, useRef } from 'react';
import styles from './SegmentedControl.module.css';
import { LucideIcon } from 'lucide-react';
import { SegmentedControlI } from '../../types/ui/segmentedControl';

const SegmentedControl = (props: SegmentedControlI) => {

    const { segments, defaultActiveIndex = 0, controlRef, handleChange } = props;

    const [activeIndex, setActiveIndex] = useState<number>(defaultActiveIndex);

    const isComponentReadyRef = useRef<boolean>(false);

    const onInputChange = (segmentId: number, index: number) => {
        setActiveIndex(index);
        handleChange(segmentId);
    }

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current!;
        const { style } = controlRef.current!;

        style.setProperty('--highlight-width', `${offsetWidth}px`);
        style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
    }, [activeIndex, controlRef, handleChange, segments]);

    // Determine when the component is "ready"
    useEffect(() => {
        isComponentReadyRef.current = true;
    }, []);

    useEffect(() => {
        setActiveIndex(defaultActiveIndex);
    }, [defaultActiveIndex]);

    const renderIcon = (icon: LucideIcon) => {
        const Icon = icon;
        return (<Icon strokeWidth={1} size={14}></Icon>);
    }

    return (
        <div className={styles.controlsContainer} ref={controlRef}>
            <div className={`${styles.controls} ${isComponentReadyRef.current ? 'ready' : 'idle'} bg-[#959595] bg-opacity-20`}>
                {segments.map((segment, index) => (
                    <div
                        key={segment.id}
                        className={`${styles.segment} ${index === activeIndex ? 'text-white' : 'text-slate-400'}`}
                        ref={segment.ref}
                    >
                        <input
                            type="radio"
                            value={segment.label}
                            id={segment.label}
                            onChange={() => onInputChange(segment.id, index)}
                            checked={index === activeIndex}
                        />
                        <div className='flex items-center px-2'>
                            {renderIcon(segment.icon)}
                            <label htmlFor={segment.label}>
                                {segment.label}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )


}

export default SegmentedControl;
