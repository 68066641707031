import { useEffect, useState } from "react";
import ReactTagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { KeyboardKeys } from "../../enums/common";

interface TagsInputI {
    keysToTriggerAdd?: Array<`${KeyboardKeys}`>,
    isResetTags?: boolean,
    handleAddTag: (tags: string[]) => void,
    checkIsValidTag?: (tag: string) => boolean,
    setHasIncompleteTag: (value: boolean) => void,
    setIsResetTags?: (reset: boolean) => void
}

const TagsInput = (props: TagsInputI) => {

    const { keysToTriggerAdd = [KeyboardKeys.COMMA, KeyboardKeys.SPACE, KeyboardKeys.ENTER], isResetTags = false, handleAddTag, checkIsValidTag, setHasIncompleteTag, setIsResetTags } = props;

    const [tags, setTags] = useState<string[]>([]);
    const [currentInputValue, setCurrentInputValue] = useState<string>('');

    useEffect(() => {
        if (currentInputValue === '') {
            setHasIncompleteTag(false);
        } else {
            setHasIncompleteTag(true);
        }
    }, [currentInputValue]);

    useEffect(() => {
        if (isResetTags && setIsResetTags) {
            setTags([]);
            setIsResetTags(false);
        }
    }, [isResetTags]);

    const handleChange = (newTags: string[]) => {
        setCurrentInputValue('');
        setTags(newTags);
        handleAddTag(newTags);
    }

    const handleValidateTag = (tag: string) => {
        if (checkIsValidTag) {
            return checkIsValidTag(tag);
        } else {
            return true;
        }
    }

    return (
        <ReactTagsInput
            value={tags}
            addOnBlur={true}
            onChange={handleChange}
            inputValue={currentInputValue}
            onChangeInput={(value) => setCurrentInputValue(value)}
            addKeys={keysToTriggerAdd}
            validate={handleValidateTag}
            className={'border border-gray-300 rounded-b-lg p-2 h-[150px] overflow-scroll light-scroll-bar'}
            focusedClassName={'border border-primary-color'}
            tagProps={{
                className: 'react-tagsinput-tag !text-primary-color !bg-lighter-primary-color !rounded-md !border-0 !py-1 !px-2 !text-sm',
                classNameRemove: 'react-tagsinput-remove !text-slate-500 !px-2 !text-sm !font-normal'
            }}
            inputProps={{
                className: 'react-tagsinput-input !ring-0 !w-[200px]',
                placeholder: ''
            }}
        />
    );
}

export default TagsInput;