import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FetchKnowledgeStateI {
    isUploadModalOpen: boolean,
    isContentMenuOpen: boolean,
    isAddNewCategoryBtnClicked: boolean,
    titleOfContentCreating: string | undefined,
    contentEditing: { document_id: string, title: string } | undefined
};

const initialState: FetchKnowledgeStateI = {
    isUploadModalOpen: false,
    isContentMenuOpen: false,
    isAddNewCategoryBtnClicked: false,
    titleOfContentCreating: undefined,
    contentEditing: undefined
};

export const fetchKnowledgeSlice = createSlice({
    name: 'fetch-knowledge',
    initialState,
    reducers: {
        setIsUploadModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUploadModalOpen = action.payload;
        },
        setIsContentMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isContentMenuOpen = action.payload;
        },
        setIsAddNewCategoryBtnClicked: (state, action: PayloadAction<boolean>) => {
            state.isAddNewCategoryBtnClicked = action.payload;
        },
        setTitleOfContentCreating: (state, action: PayloadAction<string | undefined>) => {
            state.titleOfContentCreating = action.payload;
        },
        setContentEditing: (state, action: PayloadAction<{ document_id: string, title: string } | undefined>) => {
            state.contentEditing = action.payload;
        },
        clearFetchKnowledgeState: (state) => {
            state.isUploadModalOpen = false;
            state.isContentMenuOpen = false;
            state.isAddNewCategoryBtnClicked = false;
            state.titleOfContentCreating = undefined;
            state.contentEditing = undefined;
        }
    },
})

export const { setIsUploadModalOpen, setIsContentMenuOpen, setIsAddNewCategoryBtnClicked, setTitleOfContentCreating, setContentEditing, clearFetchKnowledgeState } = fetchKnowledgeSlice.actions;
export default fetchKnowledgeSlice.reducer;