import { Fragment, useEffect, useRef } from 'react'
import { Listbox as ReactListBox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utils/styleHelpers'
import { ListboxOptionI } from '../../types/ui/listBoxes'

interface ListboxBasicI {
    options: ListboxOptionI[],
    selectedOption?: ListboxOptionI,
    listPosition?: 'top' | 'bottom' | 'right',
    listBoxStylingClasses?: string,
    buttonStylingClasses?: string,
    optionsListStylingClasses?: string,
    openOnMouseHover?: boolean,
    isListboxActive?: boolean,
    setIsListboxActive?: (isActive: boolean) => void,
    setSelectedOption: (option: ListboxOptionI) => void,
}

interface ListboxI extends ListboxBasicI {
    label: string,
}

interface ListboxWithCustomButtonI extends ListboxBasicI {
    customButton: JSX.Element,
}

const Listbox = (props: ListboxI | ListboxWithCustomButtonI) => {

    const { isListboxActive = false, options, selectedOption, listPosition = 'bottom', listBoxStylingClasses = '', buttonStylingClasses = '', optionsListStylingClasses = '', openOnMouseHover = false, setIsListboxActive, setSelectedOption } = props;
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const optionsListRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (openOnMouseHover && !isListboxActive && optionsListRef.current) {
            buttonRef.current!.click();
        }
    }, [isListboxActive, openOnMouseHover]);

    const isListboxWithCustomButton = (listboxProps: ListboxI | ListboxWithCustomButtonI): listboxProps is ListboxWithCustomButtonI => {
        return 'customButton' in listboxProps;
    }

    const handleButtonMouseHover = (isOpen: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
        setIsListboxActive?.(true);
        if (openOnMouseHover && !isOpen) {
            return event.currentTarget.click()
        } else {
            return;
        }
    }

    const handleListboxOptionsMouseLeave = (isOpen: boolean) => {
        if (openOnMouseHover && isOpen) {
            setIsListboxActive?.(false);
        } else {
            return;
        }
    }

    return (
        <div>
            <ReactListBox value={selectedOption} onChange={setSelectedOption}>
                {({ open }) => (
                    <div className={classNames("relative", listBoxStylingClasses)}>
                        <ReactListBox.Button ref={buttonRef} onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) => handleButtonMouseHover(open, event)} className={classNames('relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-primary-color focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-color sm:text-sm', buttonStylingClasses)}>
                            {!isListboxWithCustomButton(props) ?
                                <>
                                    <div className='flex items-center'>
                                        <span className="block truncate">{props.label}</span>
                                    </div>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </>
                                :
                                <>{props.customButton}</>
                            }
                        </ReactListBox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <ReactListBox.Options ref={optionsListRef} onMouseLeave={() => handleListboxOptionsMouseLeave(open)} className={classNames("absolute px-3 z-[5] max-h-60 w-full rounded-md bg-white py-5 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm light-scroll-bar overflow-auto", listPosition === 'bottom' ? 'mt-1' : listPosition === 'top' ? 'mb-1 bottom-full' : 'left-5 top-0', optionsListStylingClasses)}>
                                {
                                    options.map((opt: ListboxOptionI, index) => (
                                        <ReactListBox.Option
                                            key={index}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 px-4 flex items-center ${active ? 'bg-slate-100 text-slate-600' : 'text-slate-400'}`}
                                            value={opt}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    {opt.imageUrl && <img src={opt.imageUrl} alt="" className='size-8 mr-2 object-fill' />}
                                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                        {opt.label}
                                                    </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 right-0 flex items-center text-primary-color">
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </ReactListBox.Option>
                                    ))
                                }
                            </ReactListBox.Options>
                        </Transition>
                    </div>
                )}
            </ReactListBox>
        </div>
    )
}

export default Listbox;
