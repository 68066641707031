import { combineReducers, configureStore, UnknownAction } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import organizationReducer from './features/organization/organizationSlice';
import appReducer from './features/app/appSlice';
import fetchKnowledgeReducer from './features/fetchKnowledge/fetchKnowledgeSlice';
import fetchCollectionsReducer from './features/fetchCollections/fetchCollectionsSlice';
import agentEchoReducer from './features/agentEcho/agentEchoSlice';
import echoCollectionsReducer from './features/echoCollections/echoCollectionsSlice';
import fetchChatRoomReducer from './features/fetchChatRoom/fetchChatRoomSlice';
import breadcrumbReducer from './features/breadcrumb/breadcrumbSlice';
import indexChatRoomReducer from './features/indexChatRoom/indexChatRoomSlice';
import fetchEventsReducer from './features/fetchEvents/fetchEventsSlice';
import echoKnowledgeReducer from './features/echoKnowledge/echoKnowledgeSlice';
import profileReducer from './features/profile/profileSlice';
import storage from 'redux-persist/lib/storage'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['breadcrumb', 'fetchEvents', 'organization'],
}

const combinedReducer = combineReducers({
  agentEcho: agentEchoReducer,
  app: appReducer,
  breadcrumb: breadcrumbReducer,
  echoCollections: echoCollectionsReducer,
  echoKnowledge: echoKnowledgeReducer,
  fetchChatRoom: fetchChatRoomReducer,
  fetchCollections: fetchCollectionsReducer,
  fetchEvents: fetchEventsReducer,
  fetchKnowledge: fetchKnowledgeReducer,
  profile: profileReducer,
  indexChatRoom: indexChatRoomReducer,
  organization: organizationReducer,
  user: userReducer,
});

const rootReducer = (state: ReturnType<typeof combinedReducer> | undefined, action: UnknownAction) => {
  if (action.type === 'RESET_STATE') {
    storage.removeItem('persist:root')
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch