import { useEffect, useState } from "react";
import { OnboardingUserDetailsI } from "../../../../types/onboarding";
import { signIn } from "../../../../services/auth-service";
import ConfirmationCodeInput from "../../../../components/ConfirmationCodeInput/ConfirmationCodeInput";
import { classNames } from "../../../../utils/styleHelpers";
import { AWS_INVALID_VERIFICATION_CODE_ERROR, INCORRECT_CONFIRMATION_CODE_ERROR } from "../../../../constants/messages";
import { Auth } from "aws-amplify";
import { onboardGenericInviteUser, updateUser, updateUserAuthDetails } from "../../../../services/api/userAPI";

interface LeftComponentI {
    onboardingUserDetails: OnboardingUserDetailsI,
    inviteId: string,
    isGenericInvite: boolean,
    handleGotoNextStep: () => void
}

const LeftComponent = (props: LeftComponentI) => {

    const { onboardingUserDetails, inviteId, isGenericInvite, handleGotoNextStep } = props;

    const [confirmationCode, setConfirmationCode] = useState<string>('');
    const [isValidConfirmationCode, setIsValidConfirmationCode] = useState<boolean>(false);
    const [isIncorrectConfirmationCode, setIsIncorrectConfirmationCode] = useState<boolean>(false);

    useEffect(() => {
        if (isIncorrectConfirmationCode) {
            setIsIncorrectConfirmationCode(false);
        }
    }, [confirmationCode]);

    const handleNextButtonClick = async () => {
        setIsIncorrectConfirmationCode(false);

        try {
            await Auth.confirmSignUp(onboardingUserDetails.user.email, confirmationCode);
        } catch (error: any) {
            if (error.message === AWS_INVALID_VERIFICATION_CODE_ERROR) {
                setIsIncorrectConfirmationCode(true);
            } else {
                console.log('Error confirming signup:', error);
            }
            return;
        }

        try {
            const { username: cognitoId } = await signIn(onboardingUserDetails.user.email, onboardingUserDetails.user.password);
            await updateUserAuthDetails(cognitoId, onboardingUserDetails.user.email);
            if (!isGenericInvite) {
                await updateUser({
                    given_name: onboardingUserDetails.user.given_name,
                    family_name: onboardingUserDetails.user.family_name,
                    role_name: onboardingUserDetails.org.role_name
                });
            } else {
                await onboardGenericInviteUser(inviteId, onboardingUserDetails.user.email, onboardingUserDetails.user.given_name, onboardingUserDetails.user.family_name, onboardingUserDetails.org.role_name);
            }
            handleGotoNextStep();
        } catch (error: any) {
            console.log(error);
        }
    }

    return (
        <div className="flex flex-col gap-6 mt-10">
            <div className="text-3xl font-medium text-slate-600">Enter Confirmation Code</div>
            <div className="text-gray-400 text-xs">
                Please enter the confirmation code you received from noreply@cucopilot.com
            </div>
            <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-4">
                    <div className='flex flex-col gap-2'>
                        <div className="flex gap-2">
                            <ConfirmationCodeInput setConfirmationCode={setConfirmationCode} setIsValidConfirmationCode={setIsValidConfirmationCode} />
                        </div>
                    </div>
                    {isIncorrectConfirmationCode && <div className='text-rose-500 text-sm'>{INCORRECT_CONFIRMATION_CODE_ERROR}</div>}
                </div>
                <button
                    disabled={!isValidConfirmationCode}
                    className={classNames("text-white text-sm font-medium bg-primary-color", !isValidConfirmationCode && 'opacity-40')}
                    onClick={() => handleNextButtonClick()}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default LeftComponent;