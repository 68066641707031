import { HeaderTabsConfigI } from "../types/ui/header";
import { ROUTE_ECHO_COLLECTIONS, ROUTE_FETCH_KNOWLEDGE, ROUTE_FETCH_METRICS_ANALYTICS, ROUTE_FETCH_METRICS_QUERIES, ROUTE_FETCH_TAGS } from "./routes";

export const HEADER_TABS_CONFIGURATIONS: HeaderTabsConfigI[] = [
    {
        routes: [ROUTE_FETCH_METRICS_ANALYTICS, ROUTE_FETCH_METRICS_QUERIES],
        tabs: [
            { id: 0, name: 'Analytics', href: ROUTE_FETCH_METRICS_ANALYTICS, current: true },
            { id: 1, name: 'Queries', href: ROUTE_FETCH_METRICS_QUERIES, current: false },
        ]
    },
    {
        routes: [ROUTE_ECHO_COLLECTIONS],
        tabs: [
            { id: 0, name: 'Trends', current: true },
            { id: 1, name: 'Insights', current: false },
            { id: 2, name: 'Members', current: false }
        ],
    },
    /**{
        routes: [ROUTE_ECHO_KNOWLEDGE, ROUTE_ECHO_TAGS],
        tabs: [
            { id: 0, name: 'Knowledge', href: ROUTE_ECHO_KNOWLEDGE, current: true },
            { id: 1, name: 'Intent Tags', href: ROUTE_ECHO_TAGS, current: false },
        ],
    },**/
    {
        routes: [ROUTE_FETCH_KNOWLEDGE, ROUTE_FETCH_TAGS],
        tabs: [
            { id: 0, name: 'Knowledge', href: ROUTE_FETCH_KNOWLEDGE, current: true },
            /*{ id: 1, name: 'Intent Tags', href: ROUTE_FETCH_TAGS, current: false },*/
        ],
    }
];