export enum ModuleEnum {
    ECHO = 'echo',
    FETCH = 'fetch',
    INDEX = 'index'
};

export enum ToolEnum {
    PROFILE = 'profile',
    COLLECTIONS = 'collections',
    KNOWLEDGE = 'knowledge',
    METRICS = 'metrics',
    SETTINGS = 'settings',
    ORG_SETTINGS = 'org-settings',
    CHAT_ROOM = 'chat-room',
    EVENTS = 'events'
};