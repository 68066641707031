import axios from "axios";
import { getAmplifySessionHeaders } from "../auth-service";
import { API_URL } from "../../config";

export const uploadOrgLogo = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${API_URL}/orgs/logo/`, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error('Error uploading organization logo:', error);
    }
};

export const updateOrg = async (name: string) => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${API_URL}/orgs/update/`, { name }, { headers });
        return response;
    } catch (error) {
        console.error('Error updating organization:', error);
    }
}